<template>
    <Dialog header="Update Thumbnail" v-model:visible="display" :modal="true" class='update-thumb-modal' 
        :draggable='false' @show='onShown'>

       
        <div style="text-align:center;" ><!--style="width:100%;display:flex;align-content:center;align-items:center;"-->
               
                <!-- <div class=' thumbnail-container' >style="display:flex;flex-direction:column;" -->
                     
                    <!-- <div style="min-height: 350px;"> -->
                        <div class="thumb"  >
                            <img id='courseImage' v-show="imageUrl" :src='imageUrl' />
                            
                        </div>
                        <div  v-show="!imageUrl"> 
                            <span > No Image</span>
                            
                        </div>
                      
                    <!-- </div> -->
                    
                     <!-- <div style="margin-top:20px;">
                        
                        
                    </div> -->
                <!-- </div> -->
            </div>
        

        <template #footer>
            <div style="width:100%;display:flex;align-content:center;align-items:center;">
            <Button icon="pi pi-trash" v-show='imageUrl' @click='removeImportedImage' style="margin-right:5px;width:2.357rem;" v-tooltip.bottom="`Remove current image.`"/>
                        <Button icon="pi pi-search"  style="width:2.357rem;" @click='openFileDialogForImportingImage' v-tooltip.bottom="`Choose Image.`"/><small>(recommended size 1280 × 720 pixels)</small>
                        <ImageFileInput srcImageId='courseImage' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)' :resize="false"/>
            </div>
            <Button class='create-course-button' label="Update" :disabled='disableSubmitButton'
                :loading='loading' @click='onFooterButtonClicked()' />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';


import GroupService from '../../../service/GroupService';


import EventBus from '../../../event-bus';





import UserUtils from '../../../utilities/UserUtils';

import ImageFileInput from '../../fileInput/ImageFileInput.vue';

import { APP_URL } from '../../../common/config';



export default {
    name: 'CreateEditCourseModal',
    emits: 'thumbnail-changed',
    data() {
        return {
            userUtils: UserUtils,
            

            display: false,
           
            loading: false,


            resource: null,
            
            imageFile : null,
            imageUrl: null,
        }
    },
    components: {
        Dialog, 
        ImageFileInput,
    },

    computed: {
        disableSubmitButton() {
           
            return !this.imageUrl && !this.imageFile && this.imageUrl != this.resource?.thumbnail;
        }
    },

    methods: {
       


        openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
        selectedImage(importedImage) {
            this.imageFile = importedImage;
            this.imageUrl = importedImage.url;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },

         removeImportedImage() {
            document.getElementById('courseImage').src = APP_URL+'/assets/images/eduAccount-small.png'; // Prevents former image from rendering when uploading new image
            this.imageFile = null;
            this.imageUrl = null;
        },

         isGroupOwner() {
            return this.userUtils.isUser(this.selectedGroup.groupOwnerId);
        },
       

        onShown() {
            
        },

        open( resource) {
            
            this.resource = resource;
            this.display = true;
           
            this.imageFile = null;
            this.imageUrl = null;
            if( this.resource.thumbnail ){
                this.imageUrl = this.resource.thumbnail;
            }
          
        },

        

        onFooterButtonClicked() {
          

            
            this.loading = true;
            let req = {
                
                thumbnail: null,
            }

            if( this.imageFile ){
                req.thumbnail = this.imageFile;
            }
            else if( this.imageUrl ){
                req.thumbnail = this.createIconFromUrl(this.imageUrl);
            }

            GroupService.updateGroupCourseResourceThumbnail(this.resource.groupId, this.resource.groupCourseId,  this.resource.resourceId, req).then((resp) => {
                this.loading = false;

                if (resp.data.status === 'success') {
                    this.display = false;
                    this.$emit('thumbnail-changed');
                } else {
                    console.log("Error uploading thumbnail", resp.data);
                   this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                }
            }).catch(err=>{
                console.log("Error uploading thumbnail",err);
                this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                
            });
            
            

        },
        createIconFromUrl(url){
            let name = url.substring(url.lastIndexOf('/')+1);
            if( name.indexOf('?') > 0 ) { //trim off any ? from name
                name = name.substring(0, name.indexOf('?'));
            }
             if( name ) {
                if( name.length > 15){
                    name = name.substring(0, 14);
                }
                name = name.replaceAll('+', '');
                name = name.replaceAll('/', '');
                name = name.replaceAll('=', '');
            }
            return { name: name, url: url};
                
        }

    }

    
   
}
</script>

<style>
.update-thumb-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    height: 450px;
    border-radius: 16px;
}

.update-thumb-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.update-thumb-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.update-thumb-modal .p-dialog-content {
    height: 100%;
    /* overflow-y: hidden; */
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    /* padding: 24px 16px; */
}

.update-thumb-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>

.thumb {
    display: inline-block;
    /* vertical-align: middle; */
    /* cursor: pointer; */
    margin: 0;
    padding: 3px;
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    height: 100%;
    
}

.thumb > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.update-thumb-modal .p-dialog-content .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
 }

  .update-thumb-modal .p-dialog-content .avatar-container .rounded-square {
    display: flex;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 3px solid #32364e;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
   
    border-radius: 16px;
}

 .update-thumb-modal .p-dialog-content .avatar-container .profile-picture-button-container {
    display: flex;
    margin-bottom: 30px;
}

.update-thumb-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:enabled:focus,
.update-thumb-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete {
    border-color: #E63E3E;
    margin-right: 10px;
}
 .update-thumb-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}

.info-icon-container i {
    margin-left: auto;
}

.info-icon-container {
    padding: 0 2px 5px;
    display: flex;
}

.info-icon-container i {
    color: #00C083;
}

::v-deep(.p-accordion-header > a) {
    align-items:baseline;
}

.field-radiobutton {
    margin-bottom: 24px;
    padding: 0px 80px;
    display: flex;
    justify-content: space-around;
}

.field-radiobutton .p-radiobutton {
    margin-right: 4px;
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

.course-name-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.course-name-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.course-name-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.course-name-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.course-name-input-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.course-desc-textarea-container {
    margin-bottom: 8px;
}

.course-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.course-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.course-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.course-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.course-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.course-desc-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.course-desc-textarea-container .course-desc-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}

.input-error .input-label {
    color: #E63E3E;
}

.error-message:nth-child(2) {
    padding-top: 10px;
}

.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.create-course-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.create-course-button:hover {
    background: #00C083;
    border-color: #00C083;
}

.create-course-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}


.group-actions-switch-container {
    display: grid;
    width: 200px;
    margin: 0px 16px 16px;
}

.group-actions-switch {
    margin-left: auto;
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
}

/* .column {
    flex-grow: 1;
} */

.column {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
</style>