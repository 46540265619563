
<template>
    
        
        <DataTable v-if="selectedGroup.resourcesEnabled" :value="resources" scrollable scrollHeight="270px" class="p-datatable-sm" :reorderableColumns="true" @rowReorder="onRowReorder" tableStyle="min-width: 50rem;width:100%;">
             <Column rowReorder  style="min-width:25px;max-width:25px;" :reorderableColumn="false" v-if="isOwnerOrAdmin()"/>
            <Column field="name" header="Name" style="min-width:30%" :reorderableColumn="false" ></Column>
            <Column field="sizeKb" header="Size(Kb)"  style="min-width:100px;max-width:100px;" :reorderableColumn="false"  ></Column>
            <Column field="createdDate" header="Upload Date" :reorderableColumn="false" ></Column>
            <Column style="min-width:140px;max-width:140px;" :reorderableColumn="false" >
                <template #body="slotProps">
                    <div class="flex flex-wrap gap-3">
                        <Button type="button" icon="pi pi-image" rounded v-if="isVideo(slotProps.data)" class="resource-action" @click="editThumbnail(slotProps.data)" v-tooltip.top="'Add or Change Thumbnail'"/>
                        <Button type="button" icon="pi pi-eye" rounded v-if="isMedia(slotProps)" class="resource-action" @click="viewMedia(slotProps)" v-tooltip.top="'View'"/>
                        <Button type="button" icon="pi pi-download" rounded v-else @click="download(slotProps)" class="resource-action" v-tooltip.top="'Download'"/>
                        <Button v-if="isOwnerOrAdmin()" type="button" icon="pi pi-link" rounded @click="showInternalLink(slotProps.data)"  class="resource-action" v-tooltip.top="getLinkText(slotProps.data)"/>
                        <Button v-if="isOwnerOrAdmin()" type="button" icon="pi pi-trash" rounded @click="confirmDelete(slotProps)"  class="resource-action" v-tooltip.top="'Delete'"/>
                        
                    </div>
                </template>
            </Column>
           
        </DataTable>
         
        <FileUpload v-if="isOwnerOrAdmin()" mode="advanced" name="resourceUpload" :previewWidth="0" @upload="uploadComplete($event)" :multiple="true" :url="getUploadUrl()" 
            :maxFileSize="1073741824" chooseLabel="Choose" @before-send="setHeaders" :withCredentials="true" :disabled="!selectedGroup.resourcesEnabled"
            v-tooltip.top="(selectedGroup.resourcesEnabled ? '': 'Resources not enabled.  Go to group settings to enable it.')">

        <template #empty>
            <p>Add More Files...</p>
        </template>
        </FileUpload>
        <iframe id="downloadiframe" style="display:none;"></iframe>
         <ConfirmModal headerText="Confirm Delete" ref="confirmDeleteModal" @confirmed="onDeleteConfirmed">
            <template #content>
                <div style="padding: 10px;margin:10px;"> Are you sure you want to delete 
                <strong>{{selectedResource.name}}</strong></div>
            </template>
         </ConfirmModal>
         <ViewMediaModal ref="videoPlayer"/>
         <ImageDialog ref="imageDialog"/>
         <ShareResourceModal ref="shareResourceModal"/>
         <AddEditThumbnailModal ref="addEditThumbnailModal" @thumbnail-changed="onThumbnailChanged"/>
</template>

<script>

import GroupService from '../../service/GroupService';
import FileUpload from 'primevue/fileupload';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { API_URL } from "@/common/config";
import APIService from '../../common/APIService';
import ConfirmModal from '../modal/ConfirmModal.vue';
import ViewMediaModal from '../modal/ViewMediaModal.vue';

import AddEditThumbnailModal from './modal/AddEditThumbnailModal.vue';

import ImageDialog from '../feed/ImageDialog.vue';
import UserUtils from '../../utilities/UserUtils';


import ShareResourceModal from './modal/ShareResourceModal.vue';

//import format from 'date-fns/format';
//import parse from 'date-fns/parse';
const mediaTypes = ['.mp4', '.mov', '.jpg', '.gif', '.png'];
const videoTypes = ['.mp4', '.mov'];
const imgTypes = ['.jpg', '.gif', '.png'];
const DEFAULT_THUMBNAIL="/invrs-icon-white1.png";
export default {
    name: "Resources",
    props: {
        selectedGroup: {
            type: Object,
            required: true
        },
        
        courseId: {
            type: Number,
            required: false
        },
        feedId: {
            type: Number,
            required: false
        },

    },
    components: {
        FileUpload,
        DataTable,
        Column,
        ConfirmModal,
        ViewMediaModal,
        ImageDialog,
        ShareResourceModal,
        AddEditThumbnailModal
    },

    data() {
        return {
            DEFAULT_THUMBNAIL,
            resources: [],
            selectedResource: null,
        }
    },
    mounted() {
       
        this.reload();
    },

    watch: {
        feedId(newVal, oldVal) {
            if( newVal && newVal != oldVal) {
                this.reload();
            }
        }
    },

    methods: {

        getLinkText(res){
            if( this.isVideo(res)){
                return 'Displays a link you can use to embed the video in a lesson, task or question.';
            }
            else {
                return 'Displays a link you can use in your lessons, tasks and questions.';
            }
        },

        editThumbnail(res){//eslint-disable-line
            this.$refs.addEditThumbnailModal.open( res);
        },

        onThumbnailChanged() {
            this.reload();
        },

        showInternalLink(res) {
            //console.log("res.url = ", res);
            if( this.isVideo(res)){
                this.$refs.shareResourceModal.open(`/assets/embeds/video.html?url=https%3A%2F%2F${res.url}&poster=${(res.thumbnail ? res.thumbnail : DEFAULT_THUMBNAIL)}`);
            }
            else {
                this.$refs.shareResourceModal.open(`https://${res.url}`);
            }
        },
        isOwnerOrAdmin() {
            return this.isGroupOwner() || this.isGroupAdmin();
        },
        isGroupOwner() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId);
        },

        isGroupAdmin() {
            return UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList']);
        },

        onDeleteConfirmed() {
            GroupService.deleteGroupResource(this.selectedGroup.groupId, this.selectedResource.resourceId).then(resp=> {
                if( resp.data.status == "success") {
                    this.$toast.add({ severity: 'success', summary: `The file was successfully deleted.`, life: 3500, group: 'center'});
                    this.selectedResource = null;
                    this.reload();
                }
                else {
                    this.$toast.add({ severity: 'success', summary: `Unable to delete file.`, life: 3500, });
                }
            })
        },

        confirmDelete(slotProps) {//eslint-disable-line
            this.selectedResource = slotProps.data;
            this.$refs.confirmDeleteModal.open();
        },

        viewMedia(slotProps){ //eslint-disable-line
            this.selectedResource  = slotProps.data;
            if(this.isVideo(this.selectedResource )){
                this.$refs.videoPlayer.open(this.selectedResource.type, 'https://'+this.selectedResource.url);
            }
            else {
               this.$refs.imageDialog.open('https://'+this.selectedResource.url, false);
            }

        },

        download(slotProps){

            document.getElementById('downloadiframe').src='https://'+slotProps.data.url;
        },

        isMedia(slotProps) {
            
           
            return mediaTypes.some((el => slotProps.data.name.endsWith(el)));
            
        },

        isImage(resource) {
             return imgTypes.some((el => resource.name.endsWith(el)));
        },

         isVideo(resource) {
            
           
            return videoTypes.some((el => resource.name.endsWith(el)));
            
        },
        onRowReorder(event) {
            //console.log("row reorder");
            this.resources = event.value;
            const resourceIds = this.resources.map(res => res.resourceId);
            //console.log("onRowReorder", event.value, resourceIds);
            GroupService.updateGroupResourceOrdering(this.selectedGroup.groupId, resourceIds).then(resp => {
                if (resp.data.status == "success") {
                    //console.log("success", resp);
                }
            });
        },

        getUploadUrl() {
              if( this.courseId && this.feedId ){
                return API_URL+`/groups/${this.selectedGroup.groupId}/course/${this.courseId}/feed/${this.feedId}/resource`;
            }
            else if( this.courseId ) {
                return API_URL+`/groups/${this.selectedGroup.groupId}/course/${this.courseId}/resource`;
            }
            else if( this.feedId ){
                return API_URL+`/groups/${this.selectedGroup.groupId}/feed/${this.feedId}/resource`;
            }
            else {
              return API_URL+`/groups/${this.selectedGroup.groupId}/resource`;
            }
            
        },

       

        reload() {
            //console.log("reloading resources: courseId"+this.courseId+" feedId"+this.feedId);
            this.resources = [];
             if( this.courseId && this.feedId ){
                this.getGroupCourseModuleResources();
            }
            else if( this.courseId ) {
                this.getGroupCourseResources();
            }
            else if( this.feedId ){
                this.getGroupFeedResources();
            }
            else {
                this.getGroupResources();
            }
        },

        setHeaders(e) {
            APIService.addHeaders(e.xhr);
        },

        uploadComplete(e){//eslint-disable-line
            this.reload();
        },

        async uploadFile(e) {
            //console.log("file upload:", e);
            const file = e.files[0];
            // let blob = await fetch(file.objectURL).then((r) => r.blob());
            // let reader = new FileReader();
            // reader.readAsDataURL(blob);
            if( this.courseId && this.feedId ){
                 GroupService.uploadGroupCourseResource(this.selectedGroup.groupId, this.courseId, this.feedId, file).then(resp=>{
                    if( resp.data.status == 'success'){
                        //console.log("file uploaded");
                        this.reload();
                    }
                    else {
                        console.log("error uploading file:"+resp.data.message);
                    }
                });
            }
            else if(this.courseId){
                GroupService.uploadGroupCourseResource(this.selectedGroup.groupId, this.courseId, file).then(resp=>{
                    if( resp.data.status == 'success'){
                        //console.log("file uploaded");
                        this.reload();
                    }
                    else {
                        console.log("error uploading file:"+resp.data.message);
                    }
                });
            }

        },

        addResource() {

        },

        getGroupCourseModuleResources() {
            GroupService.getGroupCourseFeedResources(this.selectedGroup.groupId, this.courseId, this.feedId).then(resp => {
                if( resp.data.status == "success") {
                   
                    this.resources = resp.data.resources;
                   // console.log("resource:", this.resources);
                }
            })
        },

        getGroupCourseResources() {
            GroupService.getGroupCourseResources(this.selectedGroup.groupId, this.courseId).then(resp => {
                if( resp.data.status == "success") {
                   
                    this.resources = resp.data.resources;
                   // console.log("resource:", this.resources);
                }
            })
        },

        getGroupFeedResources() {

        },

        getGroupResources() {

        }

    }
}
</script>
<style scoped>

::v-deep(.p-fileupload-file-thumbnail) {
    display:none;
}

::v-deep(.p-fileupload-file-details) {
    display:flex;
    padding: 1rem 1rem;
}

::v-deep(.p-fileupload-file-details > div) {
    margin-right: 5px;
}
::v-deep(.p-fileupload-file-details > span) {
    margin-right: 5px;
}

::v-deep(.p-fileupload-buttonbar) {
    padding: .5rem;
}

::v-deep(.p-fileupload-buttonbar  > .p-button) {
    font-size: 12px;
}

::v-deep(.resource-action ) {
   
    margin-right:3px;
    width: 1.357rem;
    padding: 0.2rem 
}
::v-deep(.resource-action > span) {
    font-size:12px !important;
   
}
</style>